import { ApolloClient } from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';

import * as schemas from './constants';

const wsLink = new WebSocketLink({
  uri: process.env.SUBSCRIPTION_URL,
  options: {
    reconnect: true,
  },
});

const cache = new InMemoryCache({ addTypename: false });

const client = new ApolloClient({
  link: wsLink,
  cache,
});

export default {
  client,
  schemas,
};
