import gql from 'graphql-tag';

export const SUBSCRIBE_PENDENCIES = gql`
  subscription pendencies($token: ID!) {
    pendencies(token: $token) {
      id
      category
      type
      progress_count
      progress_current
      search_key
      solve_status
      solved_at
    }
  }
`;

export const SUBSCRIBE_PENDENCIES_FULL = gql`
  subscription pendencies($token: ID!) {
    pendencies(token: $token) {
      id
      category
      type
      progress_count
      progress_current
      search_key
      solve_status
      solved_at
      users {
        id
        user_key
        solved_at
        data {
          id
          solved_at
          input_data
          output_data
        }
      }
    }
  }
`;
