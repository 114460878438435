/* eslint-disable no-unused-expressions */
export const loadChat = ({ email, name, projects, config: { project_id } }) => {
  (function(d, r, o, z, a, k, t) {
    const project = projects.find(p => p.id === project_id);
    let customer = '';

    if (project === undefined) {
      customer = null;
    } else {
      customer = project.customer;
    }

    window.prechat_inputs = {
      email,
      name,
      customer,
    };

    [a] = d.getElementsByTagName('head');
    k = d.createElement('script');
    k.async = 1;
    t = Math.floor(Math.random() * 1000000) + 1;
    k.src = `${r}/${o}/droz.js?i=${z}&u=${r}&v=${t}`;
    a.appendChild(k);
  })(document, 'https://chat-app.meudroz.com', 'v1', process.env.CHATBOT_ID);
};

export const openChat = auth => {
  const drozSnippet = document.getElementById('droz-div');
  if (!auth) {
    return;
  }
  if (process.env.IS_PRODUCTION !== 'true') return;
  if (!drozSnippet) loadChat(window.mssp.auth.user);
};

export const closeChat = () => {
  if (process.env.IS_PRODUCTION !== 'true') return;
  const drozSnippet = document.getElementById('droz-div');
  if (drozSnippet) {
    document.location.reload(true);
  }
};
