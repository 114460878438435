import { registerApplication } from 'single-spa';
// waiting for this to be merged: https://github.com/CanopyTax/single-spa/pull/156

export default (name, activityFunction) => {
  // register the app with singleSPA and pass a reference to the store of the app as well as a reference to the globalEventDistributor
  registerApplication(
    name,
    () => global.SystemJS.import(name),
    activityFunction
  );
};
