import { start } from 'single-spa';
import { init as sentryInit } from '@sentry/browser';
import { hotjar } from 'react-hotjar';
import loadApp from './helper';
import './bootstrap';
import './events/subscriptions';
import './scripts/ga';
import './scripts/nps';
import './scripts/amplifiqueNps';
import importmap from './importmap';

if (process.env.NODE_ENV !== 'development') {
  sentryInit({ dsn: process.env.SENTRY_URL });
  hotjar.initialize(process.env.HOTJAR_ID, 6);
}

const init = () => {
  if (window.location.origin === process.env.OLD_APP_URL) {
    window.location.replace(process.env.NEW_APP_URL);
  }

  if (
    !window.location.pathname.startsWith('/core') &&
    !window.location.pathname.startsWith('/assembly') &&
    !window.location.pathname.startsWith('/contracts') &&
    !window.location.pathname.startsWith('/rdo') &&
    !window.location.pathname.startsWith('/board') &&
    !window.location.pathname.startsWith('/poc_mrv')
  )
    window.history.replaceState(null, null, '/assembly');

  loadApp('assembly', location => location.pathname.startsWith('/assembly'));

  loadApp('core', location => location.pathname.startsWith('/core'));

  loadApp('contracts', location => location.pathname.startsWith('/contracts'));

  loadApp('rdo', location => location.pathname.startsWith('/rdo'));

  loadApp('board', location => location.pathname.startsWith('/board'));

  loadApp('poc_mrv', location => location.pathname.startsWith('/poc_mrv'));

  loadApp('essentials', () => true);

  start();
};

document.head.appendChild(
  Object.assign(document.createElement('script'), {
    type: 'systemjs-importmap',
    innerHTML: JSON.stringify(importmap),
  })
);

const overrideEl = document.getElementById('import-map-overrides');

if (overrideEl) {
  document.head.appendChild(overrideEl);
}

init();
