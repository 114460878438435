export default {
  imports: {
    assembly: `${process.env.ASSEMBLY_URL}/singleSpa.js`,
    core: `${process.env.CORE_URL}/singleSpa.js`,
    contracts: `${process.env.CONTRACTS_URL}/singleSpa.js`,
    rdo: `${process.env.RDO_URL}/singleSpa.js`,
    essentials: `${process.env.ESSENTIALS_URL}/singleSpa.js`,
    board: `${process.env.BOARD_URL}/singleSpa.js`,
    poc_mrv: `${process.env.POC_MRV_URL}/singleSpa.js`,
  },
};
