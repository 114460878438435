const config = {
  development: {
    baseurl: 'https://cognito.autodocdev.com.br/logout',
    tenda: {
      response_type: 'code',
      client_id: '774hl3bma8n9efuf2bvo1md037',
      redirect_uri: 'https://tenda.autodocdev.com.br',
      state: 'tenda',
      scope: 'aws.cognito.signin.user.admin email openid profile',
      // identity_provider: 'tenda',
    },
  },
  production: {
    baseurl: 'https://cognito.autodoc.com.br/logout',
    tenda: {
      response_type: 'code',
      client_id: '26sc4d1a72lkqsre6o01jamc6g',
      redirect_uri: 'https://tenda.autodoc.com.br',
      state: 'tenda',
      scope: 'aws.cognito.signin.user.admin email openid profile',
      // identity_provider: 'tenda',
    },
  },
};

function createUrl(client, environment) {
  const { baseurl } = config[environment];

  const _config = config[environment][client];

  const url = new URL(baseurl);

  Object.keys(_config).forEach((item) => {
    url.searchParams.set(item, _config[item].toString());
  });

  return decodeURIComponent(url);
}

export const createAdUrlLogout = () => {
  const splitedHost = window.location.host.split('.');

  const client = 'tenda';
  // const client = splitedHost[0];
  //splitedHost[0] sempre vai ser eva e como ja chegou aqui quer dizer q é AD, por hora vamos forcar o tenda, ate fazer uma logica pra diferenciar cliente

  const environment = splitedHost[1].includes('dev') ? 'development' : 'production';

  return createUrl(client, environment);
};
