import t from './utils/i18n';

export const getMenuByPermission = array => {
  const permissions = window.mssp.auth.user.permissions.map(item => item.name);
  const { projects } = window.mssp.auth.user;

  if (!projects.length) {
    return [{
      path: '/assembly/',
      name: t('HOME'),
      icon: 'home',
    }];
  }

  const newMenu = [];
  array.forEach(menuItem => {
    const item = { ...menuItem };
    if (!item.permissions) return newMenu.push(item);
    if (!item.permissions.length) return null;
    if (item.permissions.some(per => permissions.includes(per))) {
      if (item.children) {
        item.children = getMenuByPermission(item.children);
      }
      newMenu.push(item);
    }
    return null;
  });
  return newMenu;
};

export default () => [
  {
    path: '/assembly/',
    name: t('HOME'),
    icon: 'home',
  },
  {
    path: '/assembly/dashboard/overview',
    name: t('DASHBOARD'),
    icon: 'dashboard',
    permissions: ['access_dashboard', 'access_dashboard_category_overview'],
  },
  {
    path: '/board/boards',
    name: t('BOARDS'),
    icon: 'fund',
    permissions: ['access_board'],
  },
  {
    path: '/assembly/programming',
    name: t('PROGRAMMING'),
    icon: 'calendar',
    permissions: ['access_program_orders'],
  },
  {
    path: '/contracts/contracts',
    name: t('CONTRACTS'),
    icon: 'container',
    permissions: ['access_contracts'],
  },
  {
    path: '/contracts/payment_reports',
    name: t('PAYMENT_REPORTS'),
    icon: 'dollar',
    permissions: ['access_payment'],
  },
  {
    path: '/assembly/reports',
    name: t('REPORTS'),
    icon: 'file-text',
    permissions: ['access_report_assembly'],
  },
  {
    path: '/rdo/calendar',
    name: t('CALENDAR'),
    icon: 'schedule',
    permissions: ['rdo_access'],
  },
  {
    path: '/assembly/register',
    name: t('PROJECT_SETUP'),
    icon: 'setting',
    permissions: [
      'access_calendar',
      'activity_access',
      'access_project_local',
      'access_supply_group',
    ],
    children: [
      {
        path: '/assembly/register/setup',
        name: 'Setup',
        permissions: [
          'activity_access',
          'access_project_local',
          'access_activity_instance',
        ],
      },
      {
        path: '/core/register/calendar',
        name: t('PROJECT_CALENDAR'),
        permissions: ['access_calendar'],
      },
    ],
  },
  {
    path: '/assembly/',
    name: t('CUSTOMER_REGISTER'),
    icon: 'form',
    permissions: [
      'access_user',
      'activity_access',
      'access_project_local',
      'access_supply_group',
      'activity_access',
      'access_fvs',
    ],
    children: [
      {
        path: '/core/register/users',
        name: t('USERS'),
        permissions: ['access_user'],
      },
      {
        path: '/core/register/contractors',
        name: t('CONTRACTORS'),
        permissions: ['access_contractor'],
      },
      {
        path: '/core/register/projects',
        name: t('PROJECTS'),
        permissions: ['access_project'],
      },
      {
        path: '/assembly/fvs',
        name: t('FVS'),
        permissions: ['create_fvs', 'update_fvs', 'access_fvs', 'delete_fvs'],
      },
    ],
  },
  {
    path: '/poc_mrv/',
    name: t('POC_MRV'),
    icon: 'fund',
    permissions: ['poc_mrv'],
    children: [
      {
        path: '/poc_mrv/mrv_v2',
        name: t('SHORT_TERM_MRV'),
        permissions: ['poc_mrv'],
      },
      {
        path: '/poc_mrv/mrv_mid_term',
        name: t('MID_TERM_MRV'),
        permissions: ['poc_mrv'],
      },
    ],
  },
];
