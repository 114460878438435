import { navigateToUrl } from 'single-spa';
import axios from 'axios';

import { LOCALSTORAGE_KEY } from '../bootstrap';
import getMenuData, { getMenuByPermission } from '../menu';
import { eev, events } from '.';
import { openChat, closeChat } from '../chat';
import { createAdUrlLogout } from '../utils/createAdUrl';

eev.on(events.MENU.TOGGLE_COLLAPSE, collapsed => {
  global.mssp.menu.collapsed = collapsed;
});

eev.on(events.APPLICATION.CHANGE_LANGUAGE, language => {
  global.mssp.application.language = language;
  if (global.mssp.auth) {
    const menuData = getMenuByPermission(getMenuData());
    global.mssp.menu.menuData = menuData;
    eev.emit(events.MENU.CHANGE_MENU_DATA, menuData);
  }
});

eev.on(events.APPLICATION.CHANGE_PROJECT, project => {
  global.mssp.application.project = project;
});

const findProject = mssp => {
  const prjct = mssp.auth.user.projects.find(
    project => project.id === mssp.application.project
  );
  return prjct ? prjct.currency_iso_code : 'BRL';
};

eev.on(events.USER.UPDATE_CONFIG, auth => {
  const { mssp } = window;

  if (!auth) {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    return;
  }

  const {
    user: { config },
  } = auth;

  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(auth));
  if (mssp.auth) {
    mssp.auth = { ...mssp.auth, ...auth };
  } else {
    mssp.auth = auth;
  }

  if (mssp.application.project !== config.project_id) {
    mssp.eev.emit(
      events.APPLICATION.CHANGE_PROJECT,
      auth.user.config.project_id
    );
  }
  mssp.auth.user.config.currency_iso_code = findProject(mssp);

  mssp.eev.emit(
    events.APPLICATION.CHANGE_LANGUAGE,
    auth.user.config.language || 'ptbr'
  );
  mssp.application.initialSetupDone = true;
  openChat(auth);
});

eev.on(events.APPLICATION.LOGOUT, async () => {
  try {
    await axios.post(
      window.mssp.services.logout,
      {
        refreshToken: localStorage.getItem('refreshToken'),
        ...(localStorage.getItem('state') && { app: 'eva' }),
      },
      {
        withCredentials: true,
        headers: {
          Authorization: window.mssp.auth.token,
        },
      }
    );
  } catch (error) {
    console.log('logout error', error.response);
  } finally {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    global.mssp.auth = null;
    closeChat();
    if (localStorage.getItem('state')) {
      localStorage.removeItem('state');
      navigateToUrl(createAdUrlLogout());
    } else {
      navigateToUrl(process.env.PLATAFORMA_URL);
    }
  }
});

eev.on(events.APPLICATION.CHANGE_BREADCRUMB, breadcrumb => {
  global.mssp.application.breadcrumb = breadcrumb;
});
