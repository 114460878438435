(function(g, u, i, d, e, s) {
  if (!u.getElementById('nps')) {
    g[e] = g[e] || [];
    const f = u.getElementsByTagName(i)[0];
    const k = u.createElement(i);
    k.id = 'nps';
    k.async = true;
    k.src = `https://static.userguiding.com/media/user-guiding-${s}-embedded.js`;
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    const ug = (g[d] = { q: [] });
    ug.c = function(n) {
      return function() {
        ug.q.push([n, arguments]);
      };
    };
    const m = [
      'previewGuide',
      'finishPreview',
      'track',
      'triggerNps',
      'hideChecklist',
      'launchChecklist',
    ];
    for (let j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
  }
})(
  window,
  document,
  'script',
  'userGuiding',
  'userGuidingLayer',
  '972458766ID'
);
