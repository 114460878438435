import { eev, events } from '../events';

function setUserAmpl({ email, name, projects, config: { project_id } }) {
  const project = projects.find(p => p.id === project_id);
  window.amplData = {
    email,
    name,
    created_at: undefined,
    company: project ? project.customer : '',
    survey: process.env.NPS_SURVEY,
    callback: () => {
      // setTimeout(() => {
      //   window.AmplSurvey.stop();
      // }, 5000);
    },
  };
  window.AmplSurvey.run('run');
}

function canIStart() {
  setUserAmpl(window.mssp.auth.user);
}

eev.on(
  events.USER.UPDATE_CONFIG,
  () => process.env.IS_PRODUCTION === 'true' && canIStart()
);
