const getUserTimezone = () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timezone || 'America/Sao_Paulo';
  } catch (e) {
    console.log(e);

    return 'America/Sao_Paulo';
  }
};

const headers = {
  'Content-Type': 'application/json',
  'X-Timezone': getUserTimezone(),
};

export default headers;
